import React, { useState } from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"

import Seo from "components/shared/Seo"
import BannerMain from "components/banners/BannerMain"
import HeaderMain from "components/headers/HeaderMain"
import HeaderNavigation from "components/headers/HeaderNavigation"
import WidgetWhatsapp from "components/widgets/WidgetWhatsapp"
import ProjectsGrid from "components/projects/ProjectsGrid"
import ProjectsSlider from "components/projects/ProjectsSlider"
import About from "components/about/About"
import Contact from "components/contact/Contact"
import ContactWhatsapp from "components/contact-whatsapp/ContactWhatsapp"
import Footer from "components/footer/Footer"

import logo from "assets/images/logo-white.png"
import close from "assets/images/close.svg"

const Pagemodal = styled.section`
  position: fixed;
  top: ${props => (props.display ? "0" : "100%")};
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  opacity: ${props => (props.display ? "1" : "0")};
  pointer-events: ${props => (props.display ? "auto" : "none")};
  transition: all 0.5s, cubic-bezier(0.7, 0, 0.4, 1) ease-out;
`
const PagemodalHeader = styled.div``
const PagemodalBrandWrapper = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
`
const PagemodalBrand = styled.img`
  width: 200px;
`
const PagemodalClose = styled.button`
  position: absolute;
  top: 40px;
  right: 40px;
  background: url(${close}) no-repeat center / 35% auto;
  z-index: 100;
  width: 50px;
  height: 50px;
  cursor: pointer;
`
const PagemodalContent = styled.div`
  width: 94%;
  margin: 200px auto 100px auto;
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
  & a {
    position: relative;
    color: #000;
    &:before {
      content: "";
      width: 104%;
      height: 8px;
      background: #02a9e7;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0.3;
      pointer-events: none;
    }
  }

  & h1,
  h2 {
    font-size: 70px;
    margin: 0 0 55px 0;
  }

  & h3 {
    font-size: 50px;
    margin: 0 0 55px 0;
  }

  & h4 {
    font-size: 30px;
    margin: 0 0 55px 0;
  }

  & h5 {
    font-size: 20px;
    margin: 0 0 55px 0;
  }

  & p {
    display: block;
    margin: 16px 0;
    font-size: 16px;
    line-height: 1.3;
  }
`
const PagemodalPage = styled.div``

const IndexPage = ({ data }) => {
  const seo = data.wpgraphql.page.seo
  const page = data.wpgraphql.page.PageIndex

  const banner = {
    video: page.bannerVideo.mediaItemUrl,
    heading: page.bannerHeading,
    content: page.bannerContent,
    labelHeading: page.bannerLabelHeading,
    labelText: page.bannerLabelSubheading,
  }

  const projectsList = page.projects.map(project => ({
    address: project.projectsRAddress,
    type: project.projectsRType,
    year: project.projectsRYear,
    website: project.projectsRWebsite,
    flats: project.projectsRFlats,
    bussinesUnits: project.projectsRBusinessunits,
    imageLazy: project.projectsRPhoto.imageFile.childImageSharp.fluid,
    image: project.projectsRPhoto.mediaItemUrl,
  }))

  const projects = {
    list: projectsList,
    cta: {
      text: page.projectsCtaText,
      status: page.projectsCtaStatus,
      buttonText: page.projectsCtaBtnText,
    },
  }

  const projectsSlider = page.referenceList.map(project => ({
    address: project.referenceListAddress,
    type: project.referenceListType,
    year: project.referenceListYear,
    flats: project.referenceListFlats,
    bussinesUnits: project.referenceListBusinessunits,
    imageLazy: project.referenceListPhoto.imageFile.childImageSharp.fluid,
  }))

  const aboutPeople = page.aboutPeople.map(person => ({
    name: person.aboutPeopleName,
    desc: person.aboutPeopleDesc,
  }))

  const about = {
    heading: page.aboutHeading,
    content: page.aboutText,
    people: aboutPeople,
    imageLazy: page.aboutImage.imageFile.childImageSharp.fluid,
  }

  const newsletter = {
    consent: page.newsletterConsent,
  }

  const contact = {
    heading: page.contactHeading,
    content: page.contactText,
  }

  const pages = page.pages.map(item => ({
    content: item.pagesContent,
    heading: item.pagesTitle,
  }))

  const [navActive, setNavActive] = useState(0)
  const [pageVisible, setPageVisible] = useState(0)

  return (
    <>
      <Seo seo={seo} />
      <HeaderMain lang="de" />
      <BannerMain
        video={banner.video}
        heading={banner.heading}
        content={banner.content}
        labelText={banner.labelText}
        labelHeading={banner.labelHeading}
      />
      <HeaderNavigation
        navigationActive={navActive}
        setNavActive={setNavActive}
      />
      <WidgetWhatsapp />
      <ProjectsGrid
        scrollToSection="4"
        projects={projects.list}
        cta={projects.cta}
      />
      <About
        heading={about.heading}
        content={about.content}
        people={about.people}
        imageLazy={about.imageLazy}
      />
      <ProjectsSlider projects={projectsSlider} />
      <Contact
        heading={contact.heading}
        content={contact.content}
        setPageVisible={setPageVisible}
      />
      <ContactWhatsapp
        title="Abonnieren Sie unseren WhatsApp-Kanal"
        text={`Auf unserem WhatsApp-Kanal erfahren Sie zuerst von neuen Projekten und exklusiven Angeboten.<br/>Erhalten Sie regelmäßige Updates von unseren Baustellen!`}
        btnText="Abonnieren"
      />
      <Footer setPageVisible={setPageVisible} />
      <Pagemodal display={pageVisible}>
        <PagemodalHeader>
          <PagemodalBrandWrapper to="/">
            <PagemodalBrand src={logo} />
          </PagemodalBrandWrapper>
          <PagemodalClose onClick={() => setPageVisible(0)} />
        </PagemodalHeader>
        <PagemodalContent>
          {pages.map((item, index) =>
            pageVisible == index + 1 ? (
              <PagemodalPage
                id={`pagemodal_${index + 1}`}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            ) : (
              ""
            )
          )}
        </PagemodalContent>
      </Pagemodal>
    </>
  )
}

export const query = graphql`
  query queryHomeDe {
    wpgraphql {
      page(id: "homepage-de", idType: URI) {
        seo {
          title
          metaDesc
          opengraphDescription
          opengraphTitle
          opengraphSiteName
          opengraphImage {
            sourceUrl
          }
        }
        PageIndex {
          bannerContent {
            bannerContentText
          }
          bannerVideo {
            mediaItemUrl
          }
          bannerHeading
          bannerLabelHeading
          bannerLabelSubheading
          projectsCtaText
          projectsCtaStatus
          projectsCtaBtnText
          projects {
            projectsRAddress
            projectsRBusinessunits
            projectsRFlats
            projectsRType
            projectsRWebsite
            projectsRYear
            projectsRPhoto {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          aboutText {
            aboutContentText
          }
          aboutHeading
          aboutPeople {
            aboutPeopleDesc
            aboutPeopleName
          }
          aboutImage {
            mediaItemUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          referenceList {
            referenceListAddress
            referenceListBusinessunits
            referenceListFlats
            referenceListType
            referenceListYear
            referenceListPhoto {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          pages {
            pagesContent
            pagesId
          }
          contactHeading
          contactText
        }
      }
    }
  }
`

export default IndexPage
